import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../context/authcontext";
import { useConfigContext } from "../../context/configcontext";
const ProtectedRoute = ({ children }: any) => {

  const location = useLocation();
  const { isLoggedIn, userData } = useAuthContext();

  const { MODULES } = useConfigContext()

  
  if (!isLoggedIn) {
    return <Navigate to={"/"} replace />;
  }
  // here we will check if the user has a access to the route of different modules
  // we will check on the basis of routes as we know dms system route start with /dms ,
  //  correspondence system route start with /cts
  // visitor management system route start with /vms
  // userData.modules = "1,2,3" // this means user has access to all the modules 1= dms, 2=cts, 3=vms
  // we have to check if user tries to access /dms route we will only allow if user modules has 1 in it
  // if user tries to access /cts route we will only allow if user modules has 2 in it
  // if user tries to access /vms route we will only allow if user modules has 3 in it

  // but first of all we will check either module is present in the config file or not
  // then we will check if user has access to the route or not

  // first check if location is starting with /dms, /cts, /vms
  // if yes check if specific module is present in the config file
  // this check is for the case when user tries to access the route directly from the url
 if(MODULES.length !== 0) {
  if (location.pathname.startsWith("/dms") && !MODULES.some((module: any) => module.link === "/dms/documents")) {
    return <Navigate to={"/welcome"} replace />;
  } else if (location.pathname.startsWith("/cts") && !MODULES.some((module: any) => module.link === "/cts/dashboard")) {
    return <Navigate to={"/welcome"} replace />
  } else if (location.pathname.startsWith("/vms") && !MODULES.some((module: any) => module.link === "/vms/dashboard")) {
    return <Navigate to={"/welcome"} replace />;
  }
}

  
  // if requested module is present in the config file then we will check if user has access to the module or not
  if (!userData?.isAdmin) {
    if (location.pathname.startsWith("/dms") && !userData.modules.includes("1")) {
      return <Navigate to={"/welcome"} replace />;
    } else if (location.pathname.startsWith("/cts") && !userData.modules.includes("2")) {
      return <Navigate to={"/welcome"} replace />
    } else if (location.pathname.startsWith("/vms") && !userData.modules.includes("3")) {
      return <Navigate to={"/welcome"} replace />;
    }
  }


  return children;
};

export default ProtectedRoute;
