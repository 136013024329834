import { NormalizedSelection, PdfViewer } from "react-pdf-selection";
import AddEditModalDX from "../business/addeditmodaldx";
import Loading from "../loading";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../context/notificationcontext";
import { useEffect, useState, useRef } from "react";
import { convertToTwoDigits } from "../../shared/globals";
import { addSignatureToPDF } from "../../shared/pdfoperations";
import useCorrespondenceActionService from "../../shared/services/correspondenceactionservice";
import useUserService from "../../shared/services/userservices";
import { useAuthContext } from "../../context/authcontext";
import { Typography } from "@mui/material";
import OTPModal from "../business/otpmodal";
import useMainAttachmentService from "../../shared/services/mainattachmentservice";

const SignatureModal = (props: any) => {
  const { t } = useTranslation();
  const { userData } = useAuthContext();
  const { setError, setInfo } = useNotificationContext();
  const { updateMainAttachment } = useMainAttachmentService();
  const { getUserById } = useUserService();
  const { sendOTP } = useCorrespondenceActionService();

  const [isLoading, setIsLoading] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [state, setState] = useState<any>({
    pdf: null,
    signature: "",
  });
  const positionRef = useRef({ x: 10, y: 10, page: 1 });
  const viewerRef = useRef<null | boolean>(null);
  const isOTPRequired = true;

  useEffect(() => {
    setIsLoading(true);
    const p1 = fetch(props.data.mainAttachmentURL, { headers: { Authorization: `Bearer ${userData.tokenInfo.token}` } })
    const p2 = getUserById(userData.userId)
    Promise.all([p1, p2])
      .then(([doc, sig]) => {
        if (sig.signatureImage === "") {
          setError(t("No signature uploaded!"));
          setState({ ...state, signature: null });

        }
        doc.arrayBuffer().then(async (attachment: any) => {
          const blob = new Blob([attachment], { type: "application/pdf" });
          const link = URL.createObjectURL(blob);
          setState({ pdf: link, signature: sig.signatureImage });
        });
      })
      .catch((err) => {
        setError(err);
        handleClose();
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      })
  }, [])


  const showRect = (highlightTip?: NormalizedSelection) => {
    positionRef.current = {
      x: highlightTip?.position.absolute.boundingRect.left as number,
      y: highlightTip?.position.absolute.boundingRect.bottom as number,
      page: highlightTip?.position.pageNumber as number,
    };
    console.log(
      highlightTip ? `New ${"image" in highlightTip ? "area" : "text"} selection` : "Reset selection",
      highlightTip?.position,
    );
  };

  const handleClose = () => {
    setOpenOTP(false);
    props.onClose();
  };

  const getOTP = async () => {
    sendOTP(props.data.correspondenceId)
      .then(() => {
        setOpenOTP(true);
      })
      .catch((err) => setError(err));
  };

  const addSignature = async () => {
    const { x, y, page } = positionRef.current;
    if (x === 10 && y === 10) {
      setError(t("Please specify the location of the signature"));
      return;
    }
    setIsLoading(true);

    if (state.signature) {
      viewerRef.current = true;
      var currentdate = new Date();
      var timeStamp =
        "Signed at: " +
        currentdate.getDate() +
        "/" +
        (currentdate.getMonth() + 1) +
        "/" +
        currentdate.getFullYear() +
        " @ " +
        convertToTwoDigits(currentdate.getHours()) +
        ":" +
        convertToTwoDigits(currentdate.getMinutes()) +
        ":" +
        convertToTwoDigits(currentdate.getSeconds());

      const type = state.signature.split(";")[0].split("/").pop();

      fetch(props.data.mainAttachmentURL, {
        headers: { Authorization: `Bearer ${userData.tokenInfo.token}` }
      })
        .then((res) => {
          res.arrayBuffer().then(async (attachment: any) => {
            const updatedFile = await addSignatureToPDF(
              attachment,
              state.signature,
              type,
              timeStamp,
              { x, y, scale: 1, page }
            );
            const blob = new Blob([updatedFile], { type: "application/pdf" });
            const file = new File([blob], "updatedDoc.pdf", {
              type: "application/pdf",
            });

            const formData = new FormData();
            formData.append('ExistingMainAttachmentId', props.data.mainAttachmentId);
            formData.append('CorrespondenceId', props.data.correspondenceId);
            formData.append('MainAttachmentFile', file);

            updateMainAttachment(formData)
              .then((result) => {
                props.toApprove();
                handleClose();
              })
              .catch((err) => {
                viewerRef.current = false;
                setError(err)
              }
              )
          });
        })
        .catch((err) => {
          viewerRef.current = false;
          setError(err);
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
      setError(t("No signature found"));
      handleClose();
    }
  };

  const Viewer = ({ document }: any) => {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "100%" }}>{document}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      {(isLoading || viewerRef.current === true)
        ?
        <Loading styles={{ height: "100%", width: "100%" }} />
        :
        <AddEditModalDX
          open={props.open}
          handleClose={handleClose}
          isSaving={isLoading}
          title={t("Add Signature")}
          maxWidth="md"
          saveLabel={t("Add")}
          onSaveClick={isOTPRequired ? getOTP : addSignature}
          onYesClick={handleClose}
        >
          {openOTP &&
            <OTPModal
              open={openOTP}
              correspondenceId={props.data.correspondenceId}
              onClose={() => setOpenOTP(false)}
              toSave={addSignature}
            />
          }
          <Typography fontSize={14} textAlign={"center"}>
            {t("Select the area where you need to put the signature")}
          </Typography>
          {state.pdf && <PdfViewer
            url={state.pdf}
            enableAreaSelection={() => true}
            scale={1}
            onAreaSelection={showRect}
            onLoad={dims => console.log(dims)}
          >
            {({ document }) => <Viewer document={document} />}
          </PdfViewer>}
        </AddEditModalDX>}
    </>
  );

};

export default SignatureModal;