import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CardActions,
  CardContent,
  Chip,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import {
  CancelOutlined,
  FormatListBulletedOutlined,
} from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import GridDX from "../../../components/layout/griddx";
import TextFieldDX from "../../../components/controls/textfielddx";
import CardDX from "../../../components/layout/carddx";
import Loading from "../../../components/loading";
import LoadingButtonDX from "../../../components/controls/loadingbuttondx";
import AppointmentConfirmModal from "../../../components/controls/appointmentconfirmmodal";
import ListPageDX from "../../../components/business/listpagedx";

import { useNotificationContext } from "../../../context/notificationcontext";
import { useAuthContext } from "../../../context/authcontext";

import {
  AppointmentStatus,
  concatenateNameWithRole,
  DateFormatter,
  toPreviewDocument,
} from "../../../shared/globals";
import TypeTranslator from "../../../shared/typetranslator";
import useCountryService from "../../../shared/services/countryservice";
import useAppointmentService from "../../../shared/services/vmsappointmentservice";
import useWorkflowService from "../../../shared/services/vmsworkflowservice";
import useVmsInboxService from "../../../shared/services/vmsinboxservice";

const AppointmentDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const visitorColumns = [
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
    },
    {
      field: "countryId",
      headerName: t("Country"),
      flex: 1,
      valueGetter: (params: any) => {
        return countries?.find((c: any) => c.countryId === params.value)
          ?.enName;
      },
    },
    {
      field: "contactNumber",
      headerName: t("Contact Number"),
      flex: 1,
    },
    {
      field: "idTypeId",
      headerName: t("ID Details"),
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              toPreviewDocument(
                params.row.visitorDocuments[0],
                userData,
                setError,
                setIsLoading
              );
            }}
            sx={{ textAlign: "left" }}
          >
            <Typography variant="body2">
              {getIdTypeValue(params.row.idTypeId)}
            </Typography>
            <Typography variant="body2">{params.row.idDetails}</Typography>
          </Link>
        );
      },
    },
    {
      field: "vehicleRegistrationNumber",
      headerName: t("Vehicle"),
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              toPreviewDocument(
                params.row.visitorDocuments[1],
                userData,
                setError,
                setIsLoading
              );
            }}
            sx={{ textAlign: "left" }}
          >
            <Typography variant="body2">
              {getIdTypeValue(params.row.vehicleRegistrationNumber)}
            </Typography>
            <Typography variant="body2">{params.row.vehicleColor}</Typography>
          </Link>
        );
      },
    },
  ];

  const { userData } = useAuthContext();
  const { setError, setInfo } = useNotificationContext();
  const { getCountries } = useCountryService();
  const {
    appointmentTypes,
    getAppointmentStatusValue,
    getAppointmentStatusChipColor,
    getIdTypeValue,
  } = TypeTranslator();
  const { getAppointmentById, approveAppointment, rejectAppointment } =
    useAppointmentService();
  const { getWorkflowById } = useWorkflowService();
  const { getInboxByUserAndAppointment } = useVmsInboxService();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [appointmentData, setAppointmentData] = useState<any>({});
  const [openCorrespondenceConfirmModal, setOpenCorrespondenceConfirmModal] =
    useState(false);
  const [correspondenceConfirmModalLabel, setCorrespondenceConfirmModalLabel] =
    useState("");
  const [confirmClickFn, setConfirmClickFn] = useState<any>(null);
  const [countries, setCountries] = useState<any[]>([]);
  const [workflowSteps, setWorkflowSteps] = useState<any>([]);
  const [actionData, setActionData] = useState<any>({
    appointmentId: id,
    userId: userData.userId,
    statusTypeId: AppointmentStatus.Approved,
    notes: "",
  });

  useEffect(() => {
    getDetails();
    getAction().then((action: any) => setActionData(action));
  }, []);

  useEffect(() => {
    getCountries().then((data) => {
      setCountries(data);
    });
  }, []);

  const getDetails = async () => {
    setIsLoading(true);
    getAppointmentById(parseInt(id as string))
      .then((data: any) => {
        setAppointmentData(data);
        getWorkflowById(data.workflowId).then((workflow) => {
          setWorkflowSteps(workflow.workflowSteps);
        });
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setIsLoading(false));
  };

  const getAction = async () => {
    return getInboxByUserAndAppointment(userData.userId, Number(id))
      .then((inbox: any) => inbox)
      .catch((err) => setError(err));
  };

  const toApproveAppointment = async (data: any) => {
    setIsSaving(true);
    console.log({ data });

    approveAppointment({
      ...data,
      statusTypeId: AppointmentStatus.Approved,
    })
      .then(() => {
        getDetails();
        setInfo(t("Appointment approved successfully"));
        handleCloseAppointmentConfirmModal();
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const toRejectAppointment = async (data: any) => {
    setIsSaving(true);
    rejectAppointment({
      ...data,
      statusTypeId: AppointmentStatus.Rejected,
    })
      .then(() => {
        getDetails();
        setInfo(t("Appointment rejected successfully"));
        handleCloseAppointmentConfirmModal();
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const handleOpenAppointmentConfirmModal = (
    confirmClickFn: any,
    label: any
  ) => {
    setOpenCorrespondenceConfirmModal(true);
    setConfirmClickFn(() => confirmClickFn);
    setCorrespondenceConfirmModalLabel(label);
  };

  const handleCloseAppointmentConfirmModal = () => {
    setCorrespondenceConfirmModalLabel("");
    setOpenCorrespondenceConfirmModal(false);
  };

  return (
    <GridDX container flexDirection="column" sx={{ pt: 1 }}>
      {isLoading && <Loading styles={{ height: "100%", width: "100%" }} />}
      {openCorrespondenceConfirmModal && (
        <AppointmentConfirmModal
          open={openCorrespondenceConfirmModal}
          onConfirmClick={confirmClickFn}
          label={correspondenceConfirmModalLabel}
          onCancelClick={handleCloseAppointmentConfirmModal}
          setActionData={setActionData}
          actionData={actionData}
        />
      )}
      <GridDX item xs={12}>
        <Typography fontSize={25} gutterBottom>
          {t("Appointment Details")}
        </Typography>
        <Chip
          sx={{ m: 0.5, height: 35, fontSize: 16 }}
          label={getAppointmentStatusValue(appointmentData?.status)}
          color={getAppointmentStatusChipColor(appointmentData?.status)}
        />
      </GridDX>

      <CardDX>
        <CardContent>
          <Typography fontSize={22} gutterBottom>
            <InfoOutlinedIcon fontSize="medium" /> {t("Visit Information")}
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <GridDX fullWidth>
            <GridDX item xs={12} flexDirection="column">
              <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Appointment Type")}
                    name="appointmentType"
                    value={
                      appointmentTypes.find(
                        (a: any) =>
                          a.value === appointmentData.appointmentTypeId
                      )?.text
                    }
                    placeholder={t("No Information")}
                  />
                </GridDX>

                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Organization Name")}
                    name="organizationName"
                    value={appointmentData?.organizationName}
                    placeholder={t("No Information")}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Visit Location")}
                    name="visitLocation"
                    value={appointmentData?.visitLocation}
                    placeholder={t("No Information")}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Visit Purpose")}
                    name="visitPurpose"
                    value={appointmentData?.visitPurpose}
                    placeholder={t("No Information")}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Visit Start Date")}
                    name="date"
                    value={DateFormatter(
                      appointmentData?.visitFromDate,
                      userData?.calendarId,
                      userData?.gregorianDateFormatId,
                      userData?.hijriDateFormatId
                    )}
                    placeholder={t("No Information")}
                  />
                </GridDX>

                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Visit End Date")}
                    name="date"
                    value={DateFormatter(
                      appointmentData?.visitEndDate,
                      userData?.calendarId,
                      userData?.gregorianDateFormatId,
                      userData?.hijriDateFormatId
                    )}
                    placeholder={t("No Information")}
                  />
                </GridDX>
              </GridDX>
            </GridDX>
          </GridDX>

          <Typography
            fontSize={20}
            sx={{
              paddingTop: 2,
              display: "inline-flex",
              alignItems: "center",
              gap: 0.5,
            }}
            gutterBottom
          >
            <FormatListBulletedOutlined fontSize="small" />
            {t("Sender Information")}
          </Typography>
          <Divider />
          <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
            <GridDX item xs={6}>
              <TextFieldDX
                readOnly
                label={t("Sender Name")}
                name="createdByUser"
                value={concatenateNameWithRole(appointmentData.createdByUser)}
                placeholder={t("No Information")}
              />
            </GridDX>
            <GridDX item xs={6}>
              <TextFieldDX
                readOnly
                label={t("Date")}
                name="createdDate"
                value={DateFormatter(
                  appointmentData?.createdDate,
                  userData?.calendarId,
                  userData?.gregorianDateFormatId,
                  userData?.hijriDateFormatId
                )}
                placeholder={t("No Information")}
              />
            </GridDX>
          </GridDX>
          {appointmentData?.status === 4 && (
            <>
              <Typography
                fontSize={20}
                sx={{
                  paddingTop: 2,
                  display: "inline-flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
                gutterBottom
              >
                <CancelOutlined color="error" fontSize="small" />
                {t("Rejection Reason")}
              </Typography>
              <GridDX item xs={12}>
                <TextFieldDX
                  readOnly
                  label={t("Reason")}
                  name="statusNotes"
                  multiline
                  rows={2}
                  value={appointmentData.statusNotes}
                  placeholder={t("No Information")}
                />
              </GridDX>
            </>
          )}
        </CardContent>
        <CardActions>
          {actionData &&
            actionData.requiredActionTypeId === 2 &&
            !actionData.actionDatetime &&
            appointmentData.status === AppointmentStatus.Pending && (
              <GridDX container justifyContent="end">
                <LoadingButtonDX
                  sx={{ mx: 1 }}
                  color="success"
                  loading={isSaving}
                  loadingPosition="end"
                  onClick={() =>
                    handleOpenAppointmentConfirmModal(
                      toApproveAppointment,
                      "Approve"
                    )
                  }
                >
                  {t("Approve")}
                </LoadingButtonDX>
                <LoadingButtonDX
                  sx={{ mx: 1 }}
                  color="error"
                  loading={isSaving}
                  loadingPosition="end"
                  onClick={() =>
                    handleOpenAppointmentConfirmModal(
                      toRejectAppointment,
                      "Reject"
                    )
                  }
                >
                  {t("Reject")}
                </LoadingButtonDX>
              </GridDX>
            )}
        </CardActions>
      </CardDX>

      <GridDX
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}
      >
        <Typography fontSize={25} component="label" sx={{ height: 32 }}>
          {t("Visitors")}
        </Typography>
      </GridDX>
      <ListPageDX
        listTitle={t("Visitors")}
        name={t("visitor")}
        rows={appointmentData?.visitors || []}
        columns={visitorColumns}
        getRowId={(row: any) => row.visitorId}
        isLoading={isLoading}
        exportToPDF={false}
        exportToCSV={false}
        searchAndFilter={false}
      />
    </GridDX>
  );
};

export default AppointmentDetails;
