import { useEffect, useState } from "react";
import AddEditModalDX from "../../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import useDocumentTypeService from "../../../shared/services/documenttypeservice";
import AutoCompleteMultiple from "../../controls/autocompletemultiple";
import useDocumentProfileService from "../../../shared/services/documentprofileservice";

const DocumentTypeModal = (props: any) => {
  const { open, handleClose, documentTypeId } = props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { addDocumentType, updateDocumentType, getDocumentTypeByID } =
    useDocumentTypeService();
  const { getDocumentProfiles } = useDocumentProfileService();
  const { setError, setInfo } = useNotificationContext();

  const defaultValues = {
    enName: "",
    arName: "",
    documentProfiles: [],
  };

  const [documentTypeData, setDocumentTypeData] = useState<any>(defaultValues);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [documentProfilesDropDownData, setDocumentProfilesDropDownData] =
    useState<any>([]);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setIsLoading(true);
    const p1 = getDocumentProfiles();
    const p2 = documentTypeId ? getDocumentTypeByID(documentTypeId) : null;
    Promise.all([
      p1.then((docProfiles: any) => {
        const dataForDocProfileDropDown = docProfiles.map((res: any) => ({
          text: languageIsEn ? res.enName : res.arName,
          value: res.documentProfileId,
        }));
        setDocumentProfilesDropDownData(dataForDocProfileDropDown);
      }),
      p2?.then((documentType: any) => {
        const { documentProfileInDocumentTypes, ...rest } = documentType;
        const dataForDocProfileDropDown = documentProfileInDocumentTypes?.map(
          (res: any) => ({
            text: languageIsEn ? res.profileEnName : res.profileArName,
            value: res.documentProfileInDocumentTypeId,
          })
        );
        setDocumentTypeData({
          ...rest,
          documentProfiles: dataForDocProfileDropDown || [],
        });
        setDataFromApi(documentType);
      }
      )])
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  // for profiles
  useEffect(() => {
    if (dataFromApi.documentProfileInDocumentTypes) {
      // check if a profile was present in dataFromApi but not in data then mark it as deleted in data from API
      // just set the flag isDeleted = true  in those specific profiles in actual dataFromApi state directly
      let deletedProfiles = dataFromApi.documentProfileInDocumentTypes.filter(
        (profile: any) =>
          !documentTypeData.documentProfiles.some(
            (item: any) => item.value === profile.documentProfileId
          )
      );
      deletedProfiles = deletedProfiles.map((profile: any) => {
        return { ...profile, isDeleted: true };
      });
      let mergedProfiles = dataFromApi.documentProfileInDocumentTypes.map(
        (profile: any) => {
          // Check if profile exists in deletedProfiles

          let deletedProfile = deletedProfiles.find(
            (deletedCat: any) =>
              deletedCat.documentProfileInDocumentTypeId ===
              profile.documentProfileInDocumentTypeId
          );
          if (deletedProfile) {
            // Merge the profile with the deletedProfile and return
            return { ...profile, isDeleted: true };
          } else {
            return profile;
          }
        }
      );
      const updatedDataFromApi = {
        ...dataFromApi,
        documentProfileInDocumentTypes: mergedProfiles,
      };
      setDataFromApi(updatedDataFromApi);
    }
  }, [documentTypeData.documentProfiles.length]);

  const validateForm = () => {
    const newErrors: any = {};

    if (documentTypeData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (documentTypeData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");
    if (documentTypeData?.documentProfiles?.length === 0)
      newErrors["documentProfiles"] = t("Document Profiles are required");
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    let profiles = documentTypeData.documentProfiles.map((profile: any) => {
      let profileData = {
        documentProfileInDocumentTypeId: 0,
        documentTypeId: documentTypeId,
        documentProfileId: profile.value,
      };
      if (dataFromApi && dataFromApi?.documentProfileInDocumentTypes) {
        let profileDataFromApi =
          dataFromApi?.documentProfileInDocumentTypes?.find(
            (profileFromApi: any) =>
              profileFromApi.documentProfileInDocumentTypeId ===
              profile.value && profileFromApi.isDeleted !== true
          );

        if (profileDataFromApi) {
          profileData.documentProfileInDocumentTypeId =
            profileDataFromApi.documentProfileInDocumentTypeId;
          profileData.documentProfileId = profileDataFromApi.documentProfileId;
        }
      }
      return profileData;
    });
    let profilesFromApi = dataFromApi?.documentProfileInDocumentTypes?.map(
      (profile: any) => {
        const { profileArName, profileEnName, ...rest } = profile;
        return {
          ...rest,
        };
      }
    );
    if (profilesFromApi) {
      profiles = [...profiles, ...profilesFromApi];
    }
    // remove duplicate categories from the array based on documentCategoryTypeId and documentCategoryId and isDeleted
    profiles = profiles.filter(
      (profile: any, index: number, self: any) =>
        index ===
        self.findIndex(
          (t: any) =>
            t.documentProfileInDocumentTypeId ===
            profile.documentProfileInDocumentTypeId &&
            t.documentProfileId === profile.documentProfileId &&
            t.isDeleted === profile.isDeleted
        )
    );

    if (validateForm()) {
      setIsLoading(true);
      if (documentTypeId) {
        const { createdDate, modifiedDate, ...rest } = documentTypeData;
        updateDocumentType({ ...rest, documentProfiles: profiles })
          .then((res: any) => {
            setInfo("Document Type updated successfully");
            handleClose(true);
          })
          .catch((err: any) => setError(err))
          .finally(() => setIsLoading(false));
      } else {
        let updatedData = {
          ...documentTypeData,
          documentProfileInDocumentTypes: documentTypeData.documentProfiles.map(
            (res: any) => res.value
          ),
        };
        const { documentProfiles, ...rest } = updatedData;
        addDocumentType(rest)
          .then((res: any) => {
            setInfo("Document Type created successfully");
            handleClose(true);
          })
          .catch((err: any) => setError(err))
          .finally(() => setIsLoading(false));
      }
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setDocumentTypeData({
      ...documentTypeData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={
        documentTypeId ? t("Edit Document Type") : t("Create Document Type")
      }
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={documentTypeData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={documentTypeData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <AutoCompleteMultiple
            label={t("Document Profiles")}
            name="documentProfiles"
            placeholder={t("Document Profile")}
            list={documentProfilesDropDownData}
            value={documentTypeData?.documentProfiles}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setDocumentTypeData({
                ...documentTypeData,
                documentProfiles: newValue,
              });
            }}
            error={errors["documentProfiles"]}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default DocumentTypeModal;
