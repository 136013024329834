import GridDX from "../../components/layout/griddx";
import PageTitle from "../../components/pagetitle";
import {
  GroupOutlined,
  GroupRemoveOutlined,
  HowToRegOutlined,
  NoAccountsOutlined,
  Person2Outlined,
  MailOutlineOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { useNotificationContext } from "../../context/notificationcontext";
import WhiteBox from "../../components/layout/whitebox";
import FilterRange from "../../components/controls/filterrange";
import { useAuthContext } from "../../context/authcontext";
import momentHijri from "moment-hijri";
import { FILTER_APIDateFormatter } from "../../shared/globals";
import VMSDashbordTabs from "../../components/pages/VMS/dashboard/dashboardtabs";
import useVMSDashboardService from "../../shared/services/vmsdashboardservice";
import moment from "moment";

const VMSDashboard = () => {
  const { getStats } = useVMSDashboardService();

  const { t, i18n } = useTranslation();
  const { setError } = useNotificationContext();
  const { userData } = useAuthContext();
  const { calendarId } = userData;

  const [stats, setStats] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState<any>(
    calendarId == 1
      ? moment().subtract(30, "days")
      : momentHijri().subtract(30, "days")
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    calendarId == 1 ? moment() : momentHijri()
  );
  const handleChangeFromDate = (date: any) => {
    setFilterFromDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));
    if (filterToDate != null && filterDate > filterToDate) {
      setError(t("From date must be less than to date"));
      setFilterFromDate(null);
    }
  };
  const handleChangeToDate = (date: any) => {
    setFilterToDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));

    if (filterFromDate != null && filterDate < filterFromDate) {
      setError(t("To date must be greater than from date"));
      setFilterToDate(null);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getStats(FILTER_APIDateFormatter(filterFromDate), FILTER_APIDateFormatter(filterToDate))
      .then((response: any) => {
        setStats(response);
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filterFromDate, filterToDate]);

  return (
    <GridDX
      container
      sx={{ width: "100%", alignItems: "center" }}
      rowSpacing={2}
      columnSpacing={1}
    >
      <GridDX item xs={2}>
        <PageTitle />
      </GridDX>
      <GridDX item xs={10} justifyContent="end">
        <FilterRange
          filterFromDate={filterFromDate}
          filterToDate={filterToDate}
          handleChangeFromDate={handleChangeFromDate}
          handleChangeToDate={handleChangeToDate}
        />
      </GridDX>
      <GridDX item lg={2} sm={3} xs={12}>
        <WhiteBox
          title={t("Visitor Visited Today")}
          loading={isLoading}
          count={stats?.visitorsVisitedToday}
          // percentage={"12%"}
          icon={<Person2Outlined sx={{ fontSize: 80 }} />}
        />
      </GridDX>
      <GridDX item lg={2} sm={3} xs={12}>
        <WhiteBox
          title={t("Group Visited Today")}
          loading={isLoading}
          count={stats?.groupsVisitedToday}
          // percentage={"0%"}
          icon={<GroupOutlined sx={{ fontSize: 80 }} />}
        />
      </GridDX>
      <GridDX item lg={2} sm={3} xs={12}>
        <WhiteBox
          title={t("Visitors on Premise")}
          loading={isLoading}
          count={stats?.visitorsOnPremise}
          // percentage={"-5%"}
          icon={<HowToRegOutlined sx={{ fontSize: 80 }} />}
        />
      </GridDX>
      <GridDX item lg={2} sm={3} xs={12}>
        <WhiteBox
          title={t("No Show Visitors")}
          loading={isLoading}
          count={stats?.noShowVisitors}
          // percentage={"-5%"}
          icon={<NoAccountsOutlined sx={{ fontSize: 80 }} />}
        />
      </GridDX>
      <GridDX item lg={2} sm={3} xs={12}>
        <WhiteBox
          title={t("No Show Groups")}
          loading={isLoading}
          count={stats?.noShowGroups}
          // percentage={"-5%"}
          icon={<GroupRemoveOutlined sx={{ fontSize: 80 }} />}
        />
      </GridDX>
      <GridDX item lg={2} sm={3} xs={12}>
        <WhiteBox
          title={t("Scheduled Visits")}
          loading={isLoading}
          count={stats?.scheduledVisits}
          // percentage={"-5%"}
          icon={<MailOutlineOutlined sx={{ fontSize: 80 }} />}
        />
      </GridDX>

      <GridDX item xs={12} sm={12}>
        <VMSDashbordTabs />
      </GridDX>
    </GridDX>
  );
};

export default VMSDashboard;
