import useSecureAPI from "../hooks/usesecureapi";

const useDocumentService = () => {
  const secureAPI = useSecureAPI();

  const getDocumentByFolder = async (id: number) => {
    const response = await secureAPI.get(`dms/Document/byfolder/${id}`);
    return response.data;
  };

  const getDocumentByID = async (id: number) => {
    const response = await secureAPI.get(`dms/Document/${id}`);
    return response.data;
  };

  const downloadDocument = async (id: number) => {
    const response = await secureAPI.get(`dms/Document/download/${id}`);
    return response.data;
  };

  const addDocument = async (data: any) => {
    const response = await secureAPI.post(`dms/Document/`, data);
    return response.data;
  };
  const bulkAddDocument = async (data: any) => {
    const response = await secureAPI.post(`dms/Document/bulk`, data);
    return response.data;
  }

  const updateDocument = async (data: any) => {
    const response = await secureAPI.put(`dms/Document/`, data);
    return response.data;
  };

  const moveDocument = async (data: any) => {
    const response = await secureAPI.put(`dms/Document/move`, data);
    return response.data;
  };

  const copyDocument = async (data: any) => {
    const response = await secureAPI.post(`dms/Document/copy`, data);
    return response.data;
  };

  const archiveDocument = async (id: number) => {
    const response = await secureAPI.put(`dms/Document/archive/${id}`);
    return response.data;
  };

  const deleteDocument = async (id: number) => {
    const response = await secureAPI.delete(`dms/Document/${id}`);
    return response.data;
  };

  const restoreDocument = async (id: number) => {
    const response = await secureAPI.put(`dms/Document/unarchive/${id}`);
    return response.data;
  };

  const bulkMoveDocument = async (data: any) => {
    const response = await secureAPI.put(`dms/Document/bulkmove`, data);
    return response.data;
  };

  const bulkCopyDocument = async (data: any) => {
    const response = await secureAPI.post(`dms/Document/bulkcopy`, data);
    return response.data;
  };

  const bulkDeleteDocument = async (data: any) => {
    const response = await secureAPI.put(`dms/Document/bulkarchive`, data);
    return response.data;
  };

  const searchInDocuments = async (keyword: string) => {
    const response = await secureAPI.get(`dms/Document/search/${keyword}`);
    return response.data;
  };

  return {
    getDocumentByFolder,
    getDocumentByID,
    addDocument,
    bulkAddDocument,
    downloadDocument,
    updateDocument,
    archiveDocument,
    deleteDocument,
    moveDocument,
    copyDocument,
    restoreDocument,
    bulkMoveDocument,
    bulkCopyDocument,
    bulkDeleteDocument,
    searchInDocuments,
  };
};

export default useDocumentService;
