import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import momentHijri from "moment-hijri";

import AddEditModalDX from "../../business/addeditmodaldx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import Loading from "../../loading";
import DateTimePickerDX from "../../controls/datetimepickerdx";

import { APIDateTimeFormatter, formatUserName } from "../../../shared/globals";
import { useAuthContext } from "../../../context/authcontext";
import useCorrespondenceRoutesService from "../../../shared/services/correspondenceroutesservice";
import useEntityService from "../../../shared/services/entityservice";
import useUserService from "../../../shared/services/userservices";
import SelectListDX from "../../controls/selectlistdx";
import CheckBoxDX from "../../controls/checkboxdx";
import AutoCompleteListDX from "../../controls/autocompletelistdx";

const CorrespondenceRoutesModal = (props: any) => {
  const { open, handleClose, correspondenceId, correspondenceRouteId } = props;

  const { t, i18n } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const { userData } = useAuthContext();
  const { calendarId, userId } = userData;
  const languageIsEn = i18n.language === "en";
  const {
    addCorrespondenceRoute,
    getRouteByCorrespondenceRouteID,
    updateCorrespondenceRoute,
  } = useCorrespondenceRoutesService();
  const { getEntity } = useEntityService();
  const { getAccessibleUsers } = useUserService();

  interface RouteData {
    correspondenceId: number;
    correspondenceRouteId: number;
    destinationTypeId: number | null;
    receiverTypeId: string | number | null;
    actionTypeId: number | null;
    userId: string | null | boolean;
    entityId: string | null | boolean;
    user: {} | string | null;
    entity: {} | string | null;
    correspondence: {} | string | null;
    requireSignature: boolean;
    actionDeadline?: string | null | Moment;
  }

  const defaultValues: RouteData = {
    correspondenceId: correspondenceId,
    correspondenceRouteId: 0,
    destinationTypeId: null,
    actionTypeId: null,
    receiverTypeId: null,
    userId: null,
    entityId: null,
    user: null,
    entity: null,
    correspondence: null,
    requireSignature: false,
    actionDeadline: null,
  };
  // hard coded values for dropdowns
  const destinationTypeDropDownData = [
    { value: 1, text: t("User") },
    { value: 2, text: t("Entity") },
  ];
  const receiverTypeDropDownData = [
    { value: 1, text: t("User") },
    { value: 2, text: t("Not a User") },
  ];
  const actionTypeDropDownData = [
    { value: 1, text: t("For Information") },
    { value: 2, text: t("For Approval") },
    { value: 3, text: t("For Delivery") },
  ];

  const [route, setRoute] = useState<RouteData>(defaultValues);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  const [usersDropDownData, setUsersDropDownData] = useState<any>([]);
  const [internalEntitiesDropDownData, setInternalEntitiesDropDownData] =
    useState<any>([]);
  const [externalEntitiesDropDownData, setExternalEntitiesDropDownData] =
    useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  //   getAccessibleUsers()
  //     .then((users) => {
  //       const filteredUsers = users?.filter((res: any) => userId !== res.id);
  //       const dataForUsersDropDown = filteredUsers.map((res: any) => ({
  //         text: formatUserName(languageIsEn, res),
  //         value: res.id,
  //       }));
  //       setUsersDropDownData(dataForUsersDropDown);
  //     }).catch((err) => setError(err))
  //     .finally(() => setIsLoading(
  //       {
  //         ...isLoading,
  //         users: false,
  //       }))

  // }
  // const getEntityDropDownData = async () => {
  //   setIsLoading(
  //     {
  //       ...isLoading,
  //       entities: true,
  //     });

  //   getEntity()
  //     .then((entities) => {
  //       const dataForInternalEntitiesDropDown = entities
  //         .filter((res: any) => res.entityTypeId === 1)
  //         .map((res: any) => ({
  //           text: languageIsEn ? res.enName : res.arName,
  //           value: res.entityId,
  //         }));
  //       const dataForExternalEntitiesDropDown = entities
  //         .filter((res: any) => res.entityTypeId === 2)
  //         .map((res: any) => ({
  //           text: languageIsEn ? res.enName : res.arName,
  //           value: res.entityId,
  //         }));

  //       setInternalEntitiesDropDownData(dataForInternalEntitiesDropDown);
  //       setExternalEntitiesDropDownData(dataForExternalEntitiesDropDown);
  //     }).catch((err) => setError(err))
  //     .finally(() => setIsLoading(
  //       {
  //         ...isLoading,
  //         entities: false,
  //       }))
  // }
  // const getRouteById = async () => {
  //   setIsLoading(
  //     {
  //       ...isLoading,
  //       route: true,
  //     });
  //   getRouteByCorrespondenceRouteID(correspondenceRouteId)
  //     .then((route) => {
  //       setRoute({ ...route, entity: null, user: null })
  //     })
  //     .catch((err) => setError(err))
  //     .finally(() =>
  //       setIsLoading(
  //         {
  //           ...isLoading,
  //           route: false,
  //         }))
  // }




  useEffect(() => {
    setIsLoading(true);
    const p1 = getAccessibleUsers()
    const p2 = getEntity()
    const p3 = correspondenceRouteId ? getRouteByCorrespondenceRouteID(correspondenceRouteId) : null
    Promise.all([
      p1.then((users) => {
        const filteredUsers = users?.filter((user: any) => userId !== user.id);
        const dataForUsersDropDown = filteredUsers.map((res: any) => ({
          text: formatUserName(languageIsEn, res),
          value: res.id,
        }));
        setUsersDropDownData(dataForUsersDropDown);
      }),
      p2.then(
        (entities) => {
          const dataForInternalEntitiesDropDown = entities
            .filter((res: any) => res.entityTypeId === 1)
            .map((res: any) => ({
              text: languageIsEn ? res.enName : res.arName,
              value: res.entityId,
            }));
          const dataForExternalEntitiesDropDown = entities
            .filter((res: any) => res.entityTypeId === 2)
            .map((res: any) => ({
              text: languageIsEn ? res.enName : res.arName,
              value: res.entityId,
            }));

          setInternalEntitiesDropDownData(dataForInternalEntitiesDropDown);
          setExternalEntitiesDropDownData(dataForExternalEntitiesDropDown);
        }
      ),
      p3?.then((route) => {
        setRoute({
          ...route,
          entity: null,
          user: null,
        });
      })
    ])
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);


  const validateForm = () => {
    const newErrors: any = {};
    if (route.destinationTypeId == null)
      newErrors[`destinationTypeId`] = t("Destination is required");
    if (route.actionTypeId == null)
      newErrors[`actionTypeId`] = t("Route type is required");
    if (route.destinationTypeId === 1 && route.userId == null)
      newErrors[`userId`] = t("Receiving user is required");
    if (route.destinationTypeId === 2 && route.entityId == null)
      newErrors[`entityId`] = t("Receiving entity is required");
    if (route.destinationTypeId === 2 && route.receiverTypeId == null)
      newErrors[`receiverTypeId`] = t("Receiver type is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const onSave = async () => {
    if (!validateForm()) {
      return;
    }
    setIsSaving(true);
    const operation = correspondenceRouteId
      ? updateCorrespondenceRoute(correspondenceRouteId, {
        ...route,
        actionDeadline: route.actionDeadline
          ? APIDateTimeFormatter(route.actionDeadline)
          : null,
      })
      : addCorrespondenceRoute(route);
    operation
      .then(() => {
        setInfo(
          t(
            correspondenceRouteId
              ? "Route updated successfully"
              : "Route created successfully"
          )
        );
        handleClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    const updatedRoute =
      name === "userId"
        ? {
          ...route,
          [name]: value,
          entityId: null,
          receiverTypeId: 1,
        }
        : name === "entityId"
          ? {
            ...route,
            entityId: value,
            userId: null,
          }
          : name === "receiverTypeId"
            ? {
              ...route,
              receiverTypeId: value,
              entityId: null,
            }
            : {
              ...route,
              [name]: type === "checkbox" ? checked : value,
            };
    setRoute(updatedRoute);
  };

  const handleDateTimeChange = (e: any) => {
    setIsChanged(true);
    setRoute({
      ...route,
      actionDeadline: moment(e),
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={
        correspondenceRouteId
          ? t("Edit Correspondence Route")
          : t("Add Correspondence Route")
      }
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {(isLoading || isSaving) && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <SelectListDX
            label={t("Select Destination")}
            items={destinationTypeDropDownData}
            name={`destinationTypeId`}
            value={route.destinationTypeId}
            onChange={(e: any) => handleInputChange(e)}
            error={errors[`destinationTypeId`]}
            InputLabelProps={{
              shrink: route.destinationTypeId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        {route.destinationTypeId === 2 && (
          <GridDX item xs={12}>
            <SelectListDX
              label={t("Select Receiver Type")}
              items={receiverTypeDropDownData}
              name="receiverTypeId"
              value={route.receiverTypeId}
              onChange={(e: any) => handleInputChange(e)}
              error={errors[`receiverTypeId`]}
              InputLabelProps={{
                shrink: route.receiverTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
        )}
        {route.destinationTypeId === 1 && (
          <GridDX item xs={12}>
            <AutoCompleteListDX
              label={t("Select Receiving User")}
              items={usersDropDownData}
              name="userId"
              value={route.userId}
              onChange={(e: any) => handleInputChange(e)}
              error={errors[`userId`]}
              InputLabelProps={{
                shrink: route.userId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
        )}
        {route.destinationTypeId === 2 && route.receiverTypeId !== null && (
          <GridDX item xs={12}>
            <SelectListDX
              label={t("Select Receiving Entity")}
              items={
                route.receiverTypeId === 1
                  ? internalEntitiesDropDownData
                  : externalEntitiesDropDownData
              }
              name="entityId"
              value={route.entityId}
              onChange={(e: any) => handleInputChange(e)}
              error={errors[`entityId`]}
              InputLabelProps={{
                shrink: route.entityId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
        )}

        <GridDX item xs={12}>
          <SelectListDX
            label={t("Select Route Type")}
            items={actionTypeDropDownData}
            name="actionTypeId"
            value={route.actionTypeId}
            onChange={(e: any) => handleInputChange(e)}
            error={errors[`actionTypeId`]}
            InputLabelProps={{
              shrink: route.actionTypeId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>

        <GridDX
          item
          xs={12}
          sx={{
            "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
              width: "100% !important",
            },
            "& .css-1rutzcu-MuiFormControl-root-MuiTextField-root": {
              width: "100% !important",
            },
          }}
        >
          <DateTimePickerDX
            label={t("Action Deadline")}
            name="actionDeadline"
            value={route.actionDeadline}
            onChange={handleDateTimeChange}
            error={errors["actionDeadline"]}
            minDate={calendarId === 1 ? moment() : momentHijri()}
          />
        </GridDX>

        {route.actionTypeId && route.actionTypeId === 2 && (
          <GridDX item xs={12}>
            <CheckBoxDX
              name="requireSignature"
              label={t("Signature is required")}
              checked={route.requireSignature}
              onChange={(e: any) => handleInputChange(e)}
            />
          </GridDX>
        )}
      </GridDX>
    </AddEditModalDX>
  );
};

export default CorrespondenceRoutesModal;