import useSecureAPI from "../hooks/usesecureapi";

const useAppointmentService = () => {
  const secureAPI = useSecureAPI();

  const getAppointments = async () => {
    const response = await secureAPI.get(`vms/Appointment`);
    return response.data;
  };

  const getFilteredAppointments = async (startDate: any, endDate: any) => {
    const response = await secureAPI.get(`vms/Appointment/filtered/${startDate}/${endDate}`);
    return response.data;
  }

  const getAppointmentById = async (id: number) => {
    const response = await secureAPI.get(`vms/Appointment/${id}`);
    return response.data;
  };

  const getAppointmentForToday = async () => {
    const response = await secureAPI.get(`vms/Appointment/today`);
    return response.data;
  }

  const addAppointment = async (data: any) => {
    const response = await secureAPI.post("vms/Appointment", data);
    return response.data;
  };

  const updateAppointment = async (data: any) => {
    const response = await secureAPI.put(`vms/Appointment`, data);
    return response.data;
  };

  const deleteAppointment = async (id: number) => {
    const response = await secureAPI.delete(`vms/Appointment/${id}`);
    return response.data;
  };


  const archiveAppointment = async (id: number) => {
    const response = await secureAPI.put(`vms/Appointment/archive/${id}`);
    return response.data;
  }

  const getArchivedAppointments = async () => {
    const response = await secureAPI.get(`vms/Appointment/archived`);
    return response.data;
  }


  const approveAppointment = async (actionData: any) => {
    const response = await secureAPI.put(
      `vms/Appointment/markasapproved`, actionData
    );
    return response.data;
  }

  const rejectAppointment = async (actionData: any) => {
    const response = await secureAPI.put(
      `vms/Appointment/markasrejected`, actionData
    );
    return response.data;
  }


  const getLastWorkflowActionOnAppointment = async (appointmentId: number) => {
    const response = await secureAPI.get(
      `vms/Inbox/lastaction/forappointment/${appointmentId}`
    );
    return response.data;
  }

  return {
    getAppointments,
    getAppointmentById,
    addAppointment,
    updateAppointment,
    deleteAppointment,
    archiveAppointment,
    getArchivedAppointments,
    approveAppointment,
    rejectAppointment,
    getFilteredAppointments,
    getAppointmentForToday,
    getLastWorkflowActionOnAppointment
  };
};

export default useAppointmentService;