import { useEffect, useState } from "react";
import AddEditModalDX from "../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import GridDX from "../layout/griddx";
import { useNotificationContext } from "../../context/notificationcontext";
import useUserService from "../../shared/services/userservices";
import Loading from "../loading";
import { useAuthContext } from "../../context/authcontext";
import useUserDelegationService from "../../shared/services/userdelegationservice";
import DatePickerDX from "../controls/datepickerdx";
import moment from "moment";
import {
  concatenateNameWithRole,
  handleDateChange,
  handleInputChange,
  sortListAlphabetically,
} from "../../shared/globals";
import AutoCompleteListDX from "../controls/autocompletelistdx";

const UserDelegationModal = (props: any) => {
  const { userData } = useAuthContext();
  const { userId, calendarId } = userData;
  const { userDelegationId, open, handleClose, delegations } = props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";

  const { setError, setInfo } = useNotificationContext();
  const { getActiveUsers } = useUserService();
  const { getUserDelegationById, addUserDelegation, updateUserDelegation } =
    useUserDelegationService();
  const defaultValues = {
    userDelegationId: userDelegationId ? userDelegationId : 0,
    fromUserId: userId,
    toUserId: null,
    startDate: null,
    endDate: null,
  };

  const [userDelegationData, setUserDelegationData] =
    useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const [usersDropDownData, setUsersDropDownData] = useState<any>([]);
  useEffect(() => {
    setIsLoading(true);
    const p1 = getActiveUsers();
    const p2 = userDelegationId
      ? getUserDelegationById(userDelegationId)
      : null;
    Promise.all([p1.then(
      (users) => {
        const filteredUsers = users?.filter((res: any) => userId !== res.id);
        const dataForUsersDropDown = filteredUsers.map((res: any) => ({
          text: concatenateNameWithRole(res),
          value: res.id,
        }));
        setUsersDropDownData(dataForUsersDropDown);
      }
    ), p2?.then(
      (delegation) => {
        if (userDelegationId) {
          setUserDelegationData(delegation);
        }
      }
    )])
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, [i18n.language]);

  const validateForm = () => {
    const newErrors: any = {};
    if (!userDelegationData.toUserId) {
      newErrors["toUserId"] = t("Please select a delegate");
    }
    if (!userDelegationData.startDate) {
      newErrors["startDate"] = t("Please select a start date");
    }
    if (!userDelegationData.endDate) {
      newErrors["endDate"] = t("Please select an end date");
    }

    // start date should be before end date
    else if (
      userDelegationData.startDate &&
      userDelegationData.endDate &&
      new Date(userDelegationData.startDate) >=
      new Date(userDelegationData.endDate)
    ) {
      newErrors["startDate"] = t("Start date should be before end date");
      newErrors["endDate"] = t("End date should be after start date");
    }
    // we have to check if any delegation is overlapping with the new delegation
    else if (userDelegationData.startDate && userDelegationData.endDate) {
      const startDate = new Date(userDelegationData.startDate);
      const endDate = new Date(userDelegationData.endDate);
      // exclude the current delegation from the list of delegations
      let filteredDelegations = userDelegationData.userDelegationId
        ? delegations.filter(
          (delegation: any) =>
            delegation.userDelegationId !==
            userDelegationData.userDelegationId
        )
        : delegations;
      const overlappingDelegation = filteredDelegations.find(
        (delegation: any) => {
          const delegationStartDate = new Date(delegation.startDate);
          const delegationEndDate = new Date(delegation.endDate);
          return (
            (startDate >= delegationStartDate &&
              startDate <= delegationEndDate) ||
            (endDate >= delegationStartDate && endDate <= delegationEndDate) ||
            (startDate <= delegationStartDate && endDate >= delegationEndDate)
          );
        }
      );

      if (overlappingDelegation) {
        newErrors["startDate"] = t("DELEGATION_OVERLAPS_WITH_EXISTING");
        newErrors["endDate"] = t("DELEGATION_OVERLAPS_WITH_EXISTING");
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    const operation = userDelegationId
      ? updateUserDelegation(userDelegationData)
      : addUserDelegation(userDelegationData);
    operation
      .then(() => {
        setInfo(
          t(
            userDelegationId
              ? "User Delegation updated successfully"
              : "User Delegation created successfully"
          )
        );
        handleClose(true);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={
        userDelegationId
          ? t("Edit User Delegation")
          : t("Create User Delegation")
      }
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}

      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <AutoCompleteListDX
            label={t("Delegate Name")}
            list={sortListAlphabetically(usersDropDownData)}
            name="toUserId"
            value={userDelegationData.toUserId}
            onChange={(event: any) => {
              handleInputChange({
                event: event,
                setIsChanged: setIsChanged,
                setData: setUserDelegationData,
                data: userDelegationData,
              });
            }}
            error={errors["toUserId"]}
            InputLabelProps={{
              shrink: userDelegationData.toUserId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        <GridDX item xs={6}>
          <DatePickerDX
            label={t("Start Date")}
            sx={{ flex: 1 }}
            name="startDate"
            value={userDelegationData.startDate}
            calendarId={calendarId}
            onChange={(value: any) => {
              handleDateChange({
                value: value,
                name: "startDate",
                setIsChanged: setIsChanged,
                setData: setUserDelegationData,
                data: userDelegationData,
              });
            }}
            error={errors["startDate"]}
            minDate={moment()}
          />
        </GridDX>
        <GridDX item xs={6}>
          <DatePickerDX
            label={t("End Date")}
            sx={{ flex: 1 }}
            name="endDate"
            value={userDelegationData.endDate}
            calendarId={calendarId}
            onChange={(value: any) => {
              handleDateChange({
                value: value,
                name: "endDate",
                setIsChanged: setIsChanged,
                setData: setUserDelegationData,
                data: userDelegationData,
              });
            }}
            error={errors["endDate"]}
            // mindate is 1 day after the start date
            minDate={moment(userDelegationData.startDate).add(1, "days")}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default UserDelegationModal;
