import { GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ListPageDX from "../../../components/business/listpagedx";
import { useNotificationContext } from "../../../context/notificationcontext";
import GridDX from "../../../components/layout/griddx";
import useCorrespondenceService from "../../../shared/services/correspondenceservice";
import {
  concatenateNameWithRole,
  DateFormatter,
  DateSorter,
} from "../../../shared/globals";
import { useAuthContext } from "../../../context/authcontext";
import TypeTranslator from "../../../shared/typetranslator";
import moment from "moment";

const ArchivedCorrespondenceList = (props: any) => {
  const { filterFromDate, filterToDate, correspondenceTypeId, domainTypeId } =
    props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { getCorrespondenceStatusValue } = TypeTranslator();
  const { setError } = useNotificationContext();
  const { getFilteredArchivedCorrespondenceList } = useCorrespondenceService();
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const [correspondenceData, setCorrespondenceData] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "correspondenceId",
      headerName: `${t("ID")}`,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      hideable: true,
    },
    {
      field: "correspondenceNumber",
      headerName: `${t("Number")}`,
      align: "center",
      headerAlign: "center",
      minWidth: 125,
    },
    {
      field: "subject",
      headerName: `${t("Subject")}`,

      headerAlign: "center",
      minWidth: 110,
    },
    {
      field: "correspondenceDate",
      headerName: `${t("Date")}`,

      align: "center",
      headerAlign: "center",
      sortComparator: DateSorter,
      minWidth: 100,
    },
    {
      field: languageIsEn
        ? "correspondenceTypeEnName"
        : "correspondenceTypeArName",
      headerName: `${t("Type")}`,

      align: "center",
      headerAlign: "center",
      minWidth: 100,
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
    },
    {
      field: "importanceLevel",
      headerName: `${t("Importance Level")}`,
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      valueGetter(params) {
        return languageIsEn
          ? params.row.importanceLevel?.enName
          : params.row.importanceLevel?.arName;
      },
    },
    {
      field: "privacyLevel",
      headerName: `${t("Privacy Level")}`,
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      valueGetter(params) {
        return languageIsEn
          ? params.row.privacyLevel?.enName
          : params.row.privacyLevel?.arName;
      },
    },
    {
      field: "domainType",
      headerName: `${t("Domain")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      valueGetter(params) {
        return languageIsEn
          ? params.row.domainType?.enName
          : params.row.domainType?.arName;
      },
    },

    {
      field: languageIsEn ? "entityNameEnName" : "entityNameArName",
      headerName: `${t("From (Entity)")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
    },
    {
      field: "routedByUser",
      headerName: `${t("From (User)")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      valueGetter(params) {
        return concatenateNameWithRole(params.row.routedByUser);
      },
    },
    {
      field: languageIsEn ? "toEntityNameEnName" : "toEntityNameArName",
      headerName: `${t("To (Entity)")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
    },
    {
      field: "toUser",
      headerName: `${t("To (User)")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 200,
      valueGetter(params) {
        return concatenateNameWithRole(params.row.toUser);
      },
    },
    {
      field: "user",
      headerName: `${t("To (User)")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return concatenateNameWithRole(params.row.user);
      },
    },
    {
      field: "entity",
      headerName: `${t("To (Entity)")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return (
          params.row.entity &&
          (languageIsEn ? params.row.entity.enName : params.row.entity.arName)
        );
      },
    },
    {
      field: languageIsEn ? "enDestination" : "arDestination",
      headerName: `${t("Current Destination")}`,
      flex: 1,
      headerAlign: "center",
      minWidth: 100,
    },
    {
      field: "modifiedDate",
      headerName: `${t("Last modified at")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      sortComparator: DateSorter,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getFilteredArchivedCorrespondenceList(
      filterFromDate,
      filterToDate,
      correspondenceTypeId,
      domainTypeId
    )
      .then((res) => {
        const sortedData = res
          .slice()
          .sort(
            (a: any, b: any) =>
              Number(b.correspondenceNumber) - Number(a.correspondenceNumber)
          );
        const preprocessedData = sortedData.map((data: any) => ({
          ...data,
          status: getCorrespondenceStatusValue(data.status), // Override the status field,
          correspondenceDate: DateFormatter(
            data.correspondenceDate,
            calendarId,
            gregorianDateFormatId,
            hijriDateFormatId
          ),
        }));
        setRows(preprocessedData);
        setCorrespondenceData(preprocessedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();
  }, [
    i18n.language,
    filterFromDate,
    filterToDate,
    correspondenceTypeId,
    domainTypeId,
  ]);
  const setGridFilterCriteria = (value: string) => {
    // if (!value.trim()) {
    //   setRows(correspondenceData);
    //   return;
    // }
    const lowercasedValue = value.toLowerCase();
    const newRows = correspondenceData?.filter(
      ({
        correspondenceId,
        subject,
        correspondenceTypeEnName,
        correspondenceTypeArName,
        entityNameEnName,
        entityNameArName,
        enDestination,
        arDestination,
        routedByUser,
        correspondenceDate,
        status,
        contentAsText,
      }: any) =>
        languageIsEn
          ? correspondenceId?.toString().includes(value) ||
            subject?.toLowerCase().includes(lowercasedValue) ||
            routedByUser?.enFullName?.toLowerCase().includes(lowercasedValue) ||
            correspondenceTypeEnName?.toLowerCase().includes(lowercasedValue) ||
            entityNameEnName?.toLowerCase().includes(lowercasedValue) ||
            enDestination?.toLowerCase().includes(lowercasedValue) ||
            status?.toLowerCase().includes(lowercasedValue) ||
            correspondenceDate?.toLowerCase().includes(lowercasedValue) ||
            contentAsText?.toLowerCase().includes(lowercasedValue)
          : correspondenceId?.toString().includes(value) ||
            subject?.toLowerCase().includes(value) ||
            routedByUser?.arFullName?.includes(value) ||
            correspondenceTypeArName?.includes(value) ||
            entityNameArName?.includes(value) ||
            arDestination?.includes(value) ||
            status?.includes(value) ||
            correspondenceDate?.toLowerCase().includes(lowercasedValue) ||
            contentAsText?.toLowerCase().includes(value)
    );
    setRows(newRows);
  };

  return (
    <GridDX
      container
      sx={{ width: "100%", height: "100%", my: 1 }}
      alignItems="center"
      columnSpacing={1}
      rowSpacing={2}
    >
      <GridDX item xs={12}>
        <ListPageDX
          listTitle={t("Archived Correspondence List")}
          name="ArchivedCorrespondence"
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.correspondenceId}
          isLoading={isLoading}
          setGridFilterCriteria={setGridFilterCriteria}
          hideColumnsOption={true}
          columnVisibilityModel={{
            correspondenceId: false,
          }}
        />
      </GridDX>
    </GridDX>
  );
};

export default ArchivedCorrespondenceList;
