import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../../context/notificationcontext";
import BoxDX from "../../layout/boxdx";
import GridDX from "../../layout/griddx";
import AddEditModalDX from "../../business/addeditmodaldx";
import Loading from "../../loading";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useFolderService from "../../../shared/services/folderservice";

const DirectoryUploadModal = (props: any) => {
  const { t } = useTranslation();
  const { addFolderWithFiles } = useFolderService();
  const { setError, setInfo } = useNotificationContext();
  const defaultValues = {
    cabinetId: props.cabinetId,
    parentFolderId: props.folderId,
    enName: "",
    arName: "",
    documents: [],
    folders: [],
    files: [] as File[],
  };

  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [data, setData] = useState(defaultValues);
  const [errors, setErrors] = useState({} as any);
  const toSave = () => {
    if (data.files.length) {
      setIsSaving(true);
      //@ts-ignore
      const formData = createStructuredFormData(data.files, data.cabinetId, data.parentFolderId);

      //@ts-ignore
      for (let pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }

      addFolderWithFiles(formData)
        .then(() => {
          setInfo(t("Documents added successfully"));
          props.handleClose(true);
        })
        .catch((err) => setError(err))
        .finally(() => setIsSaving(false));
    }
  }

  const onDrop = (acceptedFiles: any[]) => {
    // check if there is single file then show error that only folders are allowed
    if (acceptedFiles.length === 1 && acceptedFiles[0].type !== "") {
      setError(t("Only folders are allowed"));
      return;
    }
    // check if there is no file then show error that file is required
    if (acceptedFiles.length === 0) {
      setError(t("Folder must contain at least one file"));
      return;
    }
    if (acceptedFiles.length > 0) {
      setIsChanged(true);
      setData({
        ...data,
        enName:
          data.enName === ""
            ? acceptedFiles[0].path.split("/")[1]
            : data.enName,
        arName:
          data.arName === ""
            ? acceptedFiles[0].path.split("/")[1]
            : data.arName,
        files: acceptedFiles
      });
    }
  };
  // Helper to build folder tree from path
  const buildFolderTreeFromPath = (files: any) => {
    const tree = {};
    files.forEach((file: any) => {
      // Remove leading "/" and split the path into parts
      const parts = file.path.startsWith("/")
        ? file.path.substring(1).split("/")
        : file.path.split("/");

      let current = tree;

      parts.forEach((part: any, index: any) => {
        //@ts-ignore
        if (!current[part]) {
          //@ts-ignore
          current[part] = index === parts.length - 1 ? file : {}; // If it's the last part, it's a file
        }
        //@ts-ignore
        current = current[part];
      });
    });

    return tree;
  };
  // Recursive function to render tree nodes
  const renderTree = (nodes: any, parentKey = "") => {
    return Object.entries(nodes).map(([key, value]) => {
      const nodeId = `${parentKey}/${key}`; // Unique node ID
      //@ts-ignore
      if (value.path) {
        // File node
        return <TreeItem key={nodeId} nodeId={nodeId} label={key} />;
      } else {
        // Folder node
        return (
          <TreeItem key={nodeId} nodeId={nodeId} label={key}>
            {renderTree(value, nodeId)}
          </TreeItem>
        );
      }
    });
  };



  function createStructuredFormData(files: { path: string; file: File; }[], cabinetId: string, parentFolderId: string) {
    console.log(files, "files");
    const formData = new FormData();

    //@ts-ignore
    function buildStructure(folderPath: string | any[], files: any[]) {
      console.log(folderPath, "folderPath");
      console.log(files, "files");
      const documents: {
        name: any; // File name without extension
        file: any;
      }[] = [];
      const foldersMap = new Map();

      files.forEach((file: { path: string | any[]; file: any; }) => {
        console.log(file, "file");
        const relativePath = file.path.slice(folderPath.length + 1);
        //@ts-ignore
        const segments = relativePath.split("/");

        if (segments.length === 1) {
          // File directly in the current folder
          documents.push({
            name: segments[0].split(".")[0], // File name without extension
            file: file, // Add actual FileObject if available
          });
        } else {
          // File in a subfolder
          const folderName = segments[0];
          if (!foldersMap.has(folderName)) {
            foldersMap.set(folderName, []);
          }
          foldersMap.get(folderName).push(file);
        }
      });

      // Build folder structure recursively
      const folders = [];
      //@ts-ignore
      for (const [folderName, folderFiles] of foldersMap.entries()) {
        folders.push({
          name: folderName,
          structure: buildStructure(`${folderPath}/${folderName}`, folderFiles),
        });
      }

      return { documents, folders };
    }

    function appendToFormData(formData: FormData, structure: { documents: any[]; folders: any[]; }, prefix = "") {
      structure.documents.forEach((doc: { name: any; file: any; }, index: any) => {
        formData.append(`${prefix}Docs[${index}].FolderId`, "0");
        formData.append(`${prefix}Docs[${index}].EnName`, doc.name);
        formData.append(`${prefix}Docs[${index}].ArName`, doc.name);
        formData.append(`${prefix}Docs[${index}].File`, doc.file);
      });

      structure.folders.forEach((folder: { name: any; structure: any; }, index: any) => {
        console.log(folder, "folder");
        const folderPrefix = `${prefix}Folders[${index}].`;
        //@ts-ignore
        formData.append(`${folderPrefix}CabinetId`, cabinetId);
        //@ts-ignore
        formData.append(`${folderPrefix}FolderId`, "0");
        formData.append(`${folderPrefix}EnName`, folder.name);
        formData.append(`${folderPrefix}ArName`, folder.name);
        appendToFormData(formData, folder.structure, folderPrefix);
      });
    }

    const rootStructure = buildStructure("/" + files[0].path.split("/")[1], files);
    //@ts-ignore
    formData.append("CabinetId", cabinetId);
    //@ts-ignore
    parentFolderId && formData.append("ParentFolderId", parentFolderId);
    formData.append("EnName", files[0].path.split("/")[1]);
    formData.append("ArName", files[0].path.split("/")[1]);
    appendToFormData(formData, rootStructure);

    return formData;
  }







  return (
    <AddEditModalDX
      open={props.open}
      handleClose={props.handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={t("Upload Directory")}
      maxWidth="md"
      onSaveClick={toSave}
    >
      {isSaving && <Loading styles={{ height: "100%", width: "100%", left: 0 }} />}
      <GridDX
        container
        sx={{ width: "100%", height: "100%", my: 1 }}
        alignItems="center"
        columnSpacing={1}
        rowSpacing={2}
      >
        <GridDX item xs={12} flexDirection="column">
          {!data.files.length && (
            <Dropzone
              onDrop={onDrop}
              noClick
              noKeyboard
            >
              {({ getRootProps, getInputProps }) => (
                <BoxDX
                  {...getRootProps({ className: "dropzone" })}
                  sx={{
                    width: "100%",
                    height: 100,
                    border: errors["files"]
                      ? "1px dashed red"
                      : "1px dashed grey",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <GridDX item xs={12} justifyContent="center">
                    <input
                      {...getInputProps()}
                      //@ts-ignore
                      directory=""
                      webkitdirectory="" // Allow folder selection
                    />
                  </GridDX>
                  <GridDX
                    item
                    xs={12}
                    height={100}
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Typography
                      sx={{
                        fontSize: 18,
                        textAlign: "center",
                      }}
                    >
                      {t("Drag and drop a folder here")}
                    </Typography>
                  </GridDX>
                </BoxDX>
              )}
            </Dropzone>
          )}
          <GridDX container spacing={1} sx={{ mt: 2 }}>
            {/* Display Uploaded Files */}
            {data.files.length > 0 && (
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                {renderTree(buildFolderTreeFromPath(data.files))}
              </TreeView>
            )}

          </GridDX>
        </GridDX>


      </GridDX>
    </AddEditModalDX>
  );
};

export default DirectoryUploadModal;
