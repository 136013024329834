import useSecureAPI from "../hooks/usesecureapi";

const useInboxService = () => {
  const secureAPI = useSecureAPI();

  const getInboxByUser = async (id: any) => {
    const response = await secureAPI.get(`Inbox/byuser/${id}`);
    return response.data;
  };
  
  const getInboxByUserAndCorrespondence = async (id: any, correspondenceId: any) => {
    const response = await secureAPI.get(`Inbox/action/byuser/bycorrespondence/${id}/${correspondenceId}`);
    return response.data;
  }
  const markAsRead = async (ids: any, ) => {
    const response = await secureAPI.put(`Inbox/markasopened`, ids);
    return response.data;
  };

  const markAsUnread = async (ids: any, ) => {
    const response = await secureAPI.put(`Inbox/markasunopened`, ids);
    return response.data;
  };

  const markAsArchived = async (ids: any, ) => {
    const response = await secureAPI.put(`Inbox/archive`, ids);
    return response.data;
  };

  const deleteInbox = async (ids: any, ) => {
    const response = await secureAPI.put(`Inbox/delete`, ids);
    return response.data;
  };

  return {
    getInboxByUser,
    getInboxByUserAndCorrespondence,
    markAsRead,
    markAsUnread,
    markAsArchived,
    deleteInbox,
  };
};

export default useInboxService;
