import GridDX from "../components/layout/griddx";
import CardDX from "../components/layout/carddx";
import {
  FolderOpenOutlined,
  ForwardToInboxOutlined,
  Groups2Outlined,
} from "@mui/icons-material";
import { CardContent, IconButton, Typography } from "@mui/material";
import { useAuthContext } from "../context/authcontext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useConfigContext } from "../context/configcontext";

const LandingPage = () => {
  const navigate = useNavigate();
  const { updateUserDetails, userData } = useAuthContext();
  const { t } = useTranslation();

  const { MODULES } = useConfigContext();
  const moduleIds = MODULES?.map((module: { id: number }) => module.id); 

  
  const modules = [
    {
      id: 1,
      icon: <FolderOpenOutlined fontSize="large" />,
      title: t("Document Management and Archiving System"),
      shortTitle: "DMS",
      description: t(
        "Organize your firm’s documents and automate your workflow."
      ),
      link: "/dms/documents",
      bgColor: "#E1F8DC",
    },
    {
      id: 2,
      icon: <ForwardToInboxOutlined fontSize="large" />,
      title: t("Electronic Correspondence System"),
      shortTitle: "CTS",
      description: t(
        "Manage your correspondence with clients and other employees."
      ),
      link: "/cts/dashboard",
      bgColor: "#FEF8DD",
    },
    // visitor management system
    {
      id: 3,
      icon: <Groups2Outlined fontSize="large" />,
      title: t("Visitor Management System"),
      shortTitle: "VMS",
      description: t("Manage your visitors with ease."),
      link: "/vms/dashboard",
      bgColor: "#dceaf8",
    },
  ];
 
  return (
    <GridDX
      container
      sx={{ width: "100%", display: "block" }}
      rowSpacing={2}
      columnSpacing={1}
      p={4}
    >
      <Typography variant="h4" align="center" gutterBottom>
        HEXMATE
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        {t("Welcome to HEXMATE, the all-in-one solution for your firm.")}
      </Typography>
      <GridDX
        xs={12}
        sx={{ justifyContent: "center", height: 75, alignItems: "center" }}
      >
        <Typography variant="h5" align="center" paragraph>
          {t("Trial Version")}
        </Typography>
      </GridDX>
      <GridDX container spacing={4}>
        {modules
        // first check if modules are configured in config file
          .filter((module) => moduleIds.includes(module.id))
          .filter((module) =>
            !userData.isAdmin ? userData.modules?.includes(module.id) : true
          )
          .map((module, index) => (
            <GridDX item xs={12} sm={6} md={4} key={index}>
              <CardDX
                sx={{
                  width: "100%",
                  cursor: "pointer",
                  backgroundColor: module.bgColor,
                }}
                onClick={() => {
                  // console.log("module222",module)
                  updateUserDetails({
                    ...userData,
                    module: module.shortTitle,
                  });
                  navigate(module.link);
                }}
              >
                <CardContent>
                  <IconButton aria-label={module.title}>
                    {module.icon}
                  </IconButton>
                  <Typography variant="h5" fontWeight={800} gutterBottom>
                    {module.title}
                  </Typography>
                  {/* <Typography variant="body2" paragraph>
                    {module.description}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      updateUserDetails({
                        ...userData,
                        module: module.shortTitle,
                      });
                      navigate(module.link);
                    }}
                  >
                    {t("Explore")} {module.shortTitle}
                  </Button> */}
                </CardContent>
              </CardDX>
            </GridDX>
          ))}
      </GridDX>
    </GridDX>
  );
};

export default LandingPage;
