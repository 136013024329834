import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { LineChart } from "@mui/x-charts";
import {
  AttachFileOutlined,
  SendOutlined,
  GroupsOutlined,
} from "@mui/icons-material";
import moment from "moment";
import momentHijri from "moment-hijri";

import GridDX from "../../layout/griddx";
import BoxDX from "../../layout/boxdx";
import useDashboardService from "../../../shared/services/dashboardservice";

import { useNotificationContext } from "../../../context/notificationcontext";
import { useAuthContext } from "../../../context/authcontext";

import Loading from "../../loading";
import ActionDropDownMenuDx from "../../controls/dropdownmenudx";
import DatePickerDX from "../../controls/datepickerdx";
import FilterRange from "../../controls/filterrange";
import { FILTER_APIDateFormatter } from "../../../shared/globals";

const Statistics = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setError } = useNotificationContext();

  const { userData } = useAuthContext();
  const { calendarId } = userData;

  const [loadingMonthlyPerformance, setLoadingMonthlyPerformance] =
    useState(false);

  const [loadingStatistics, setLoadingStatistics] = useState(false);
  const { getMonthlyStatistics, getStats } = useDashboardService();

  const [monthlyPerformanceData, setMonthlyPerformanceData] = useState<
    any[] | null
  >([]);
  const [uData, setUData] = useState<any[] | null>([0]);
  const [pData, setPData] = useState<any[] | null>([0]);
  const [xLabels, setXLabels] = useState<any[] | null>([""]);

  const [stats, setStats] = useState<any>([]);
  const [recallStats, setReCallStats] = useState<any>(false);

  const [filter, setFilter] = useState<string>(t(""));
  const [filterFromDate, setFilterFromDate] = useState<any>(null);
  const [filterToDate, setFilterToDate] = useState<any>(null);

  useEffect(() => {
    if (filter == t("Filter")) {
      if (calendarId == 1) {
        setFilterFromDate(moment("20140101"));
        setFilterToDate(moment());
      } else {
        setFilterFromDate(momentHijri("20140101"));
        setFilterToDate(momentHijri());
      }
    }
  }, [filter]);

  const getMonthlyPerformance = async (
    formattedFromDate: any,
    formattedToDate: any
  ) => {
    setLoadingMonthlyPerformance(true);
    getMonthlyStatistics(formattedFromDate, formattedToDate)
      .then((monthlyPerformanceStatistics) => {
        setMonthlyPerformanceData(monthlyPerformanceStatistics);
        filterDataHelperFunction(monthlyPerformanceStatistics);
      })
      .catch((err) => setError(err))
      .finally(() => setLoadingMonthlyPerformance(false));
  };

  const getStatistics = async (
    formattedFromDate: any,
    formattedToDate: any
  ) => {
    setLoadingStatistics(true);
    getStats(formattedFromDate, formattedToDate)
      .then((res) => {
        setStats([
          {
            name: t("Total Attachments"),
            icon: <AttachFileOutlined />,
            list: 1,
            //@ts-ignore
            Badge: res?.totalAttachments,
          },
          //@ts-ignore
          {
            name: t("Total Routes"),
            icon: <SendOutlined />,
            list: 1,
            Badge: res?.totalRoutes,
          },
          //@ts-ignore
          {
            name: t("Total Users"),
            icon: <GroupsOutlined />,
            list: 1,
            Badge: res?.totalUsers,
          },
        ]);
      })
      .catch((err) => setError(err))
      .finally(() => setLoadingStatistics(false));
  };

  const customFilters = [
    {
      label: t("Filter by year"),
      onClick: () => setFilter(t("Filter by year")),
    },
    {
      label: t("Filter by month"),
      onClick: () => setFilter(t("Filter by month")),
    },
    {
      label: t("Filter by range"),
      onClick: () => setFilter(t("Filter by range")),
    },
    {
      label: t("Reset"),
      onClick: () => handleFilterReset(),
    },
  ];

  const handleFilterReset = () => {
    setFilter(t("Filter"));
    filterDataHelperFunction(monthlyPerformanceData);
  };

  const handleFilterYear = (date: any) => {
    const filterYear = new Date(date).getFullYear();
    setReCallStats(true);
    setFilterFromDate(
      calendarId == 1
        ? new Date(`${filterYear}-1-1`)
        : momentHijri(new Date(`${filterYear}-1-1`))
    );
    setFilterToDate(
      calendarId == 1
        ? new Date(`${filterYear}-12-31`)
        : momentHijri(new Date(`${filterYear}-12-31`))
    );

    const filteredData = monthlyPerformanceData?.filter((item) => {
      const year = new Date(item.month).getFullYear();
      return year === filterYear;
    });
    filterDataHelperFunction(filteredData);
  };

  const handleFilterMonth = (month: any) => {
    const filterYear = new Date(month).getFullYear();
    const filterMonth = new Date(month).getMonth();

    setReCallStats(true);
    // from date is start of month
    setFilterFromDate(
      calendarId == 1
        ? new Date(`${filterYear}-${filterMonth + 1}-1`)
        : momentHijri(new Date(`${filterYear}-${filterMonth + 1}-1`))
    );
    // to date is end of month
    setFilterToDate(
      calendarId == 1
        ? new Date(filterYear, filterMonth + 1, 1)
        : momentHijri(new Date(filterYear, filterMonth + 1, 1))
    );

    const filteredData = monthlyPerformanceData?.filter((item) => {
      const year = new Date(item.month).getFullYear();
      const month = new Date(item.month).getMonth();
      return year === filterYear && month === filterMonth;
    });
    filterDataHelperFunction(filteredData);
  };

  const handleChangeFromDate = (date: any) => {
    setFilterFromDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));
    if (filterToDate != null && filterDate > filterToDate) {
      setError(t("From date must be less than to date"));
      setFilterFromDate(null);
    } else if (filterToDate != null && filterDate < filterToDate) {
      const filteredData = monthlyPerformanceData?.filter((item) => {
        const date =
          calendarId == 1
            ? new Date(item.month)
            : momentHijri(new Date(item.month));
        return date >= filterDate && date <= filterToDate;
      });
      filterDataHelperFunction(filteredData);
    } else {
      const filteredData = monthlyPerformanceData?.filter((item) => {
        const date =
          calendarId == 1
            ? new Date(item.month)
            : momentHijri(new Date(item.month));
        return date >= filterDate;
      });
      filterDataHelperFunction(filteredData);
    }
  };
  const handleChangeToDate = (date: any) => {
    setFilterToDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));

    if (filterFromDate != null && filterDate < filterFromDate) {
      setError(t("To date must be greater than from date"));
      setFilterToDate(null);
    } else if (filterFromDate != null && filterDate > filterFromDate) {
      const filteredData = monthlyPerformanceData?.filter((item) => {
        const date =
          calendarId == 1
            ? new Date(item.month)
            : momentHijri(new Date(item.month));
        return date <= filterDate && date >= filterFromDate;
      });
      filterDataHelperFunction(filteredData);
    } else {
      const filteredData = monthlyPerformanceData?.filter((item) => {
        const date =
          calendarId == 1
            ? new Date(item.month)
            : momentHijri(new Date(item.month));
        return date <= filterDate;
      });
      filterDataHelperFunction(filteredData);
    }
  };
  const filterDataHelperFunction = (filteredData: any) => {
    if (filteredData && filteredData?.length > 0) {
      const uData = filteredData?.map((item: any) => item.totalClosed);
      const pData = filteredData?.map((item: any) => item.totalOpened);
      const xLabels = filteredData?.map((item: any) => {
        const label =
          calendarId == 1
            ? new Date(item.month).toLocaleString("default", { month: "short" })
            : momentHijri(new Date(item.month)).format("iMMMM");

        return label;
      });
      setUData(uData);
      setPData(pData);
      setXLabels(xLabels);
    } else {
      setUData([0]);
      setPData([0]);
      setXLabels([""]);
    }
  };

  const DatePickerStyle = {
    height: "40px",
    width: "25%",
    mx: 1,
    "& .MuiInputBase-root": {
      height: "inherit",
      px: 1,
      py: 0,
      margin: 0,
    },
    "& .MuiButtonBase-root": {
      padding: 0,
      paddingRight: "20px",
    },
  };

  // call handle reset not even first time but only render when language changes

  useEffect(() => {
    handleFilterReset();
  }, [i18n.language]);

  useEffect(() => {
    let formattedFromDate =
      filterFromDate && filterFromDate != null
        ? FILTER_APIDateFormatter(filterFromDate)
        : null;
    let formattedToDate =
      filterToDate && filterToDate != null
        ? FILTER_APIDateFormatter(filterToDate)
        : null;
    if (filterFromDate && filterToDate && recallStats) {
      getStatistics(formattedFromDate, formattedToDate);
      setReCallStats(false);
    } else if (filterFromDate && filterToDate) {
      getMonthlyPerformance(formattedFromDate, formattedToDate);
      getStatistics(formattedFromDate, formattedToDate);
    }
  }, [i18n.language, filterFromDate, filterToDate]);

  return (
    <GridDX container sx={{ width: "100%" }} rowSpacing={2} columnSpacing={1}>
      <GridDX
        item
        xs={12}
        sx={{
          my: 1,
          height: "40px !important",
          display: "flex",
          alignItems: "center",
          justifyContent: languageIsEn ? "flex-end" : "flex-start",
        }}
      >
        <ActionDropDownMenuDx label={filter} actions={customFilters} />
        {filter === t("Filter by year") && (
          <DatePickerDX
            format="YYYY"
            openTo="year"
            views={["year"]}
            onChange={(year: any) => handleFilterYear(year)}
            minDate={
              calendarId == 1 ? moment("2020-01-01") : momentHijri("2020-01-01")
            }
            sx={DatePickerStyle}
          />
        )}
        {filter === t("Filter by month") && (
          <DatePickerDX
            format="YYYY-MM"
            views={["year", "month"]}
            openTo="month"
            onChange={(month: any) => handleFilterMonth(month)}
            minDate={
              calendarId == 1 ? moment("2020-01-01") : momentHijri("2020-01-01")
            }
            sx={DatePickerStyle}
          />
        )}
        {filter === t("Filter by range") && (
          <FilterRange
            filterFromDate={filterFromDate}
            filterToDate={filterToDate}
            handleChangeFromDate={handleChangeFromDate}
            handleChangeToDate={handleChangeToDate}
          />
        )}
      </GridDX>
      <GridDX item xs={12} lg={8} flexDirection={"column"}>
        <Typography sx={{ mt: 2 }}>
          {t("Created/Closed Correspondence")}
        </Typography>
        {loadingMonthlyPerformance ? (
          <div style={{ minHeight: "200px", position: "relative" }}>
            <Loading
              styles={{
                height: "100%",
                width: "100%",
                left: 0,
                position: "absolute",
              }}
            />
          </div>
        ) : (
          uData &&
          pData &&
          xLabels && (
            <LineChart
              margin={{ top: 50, left: 75, right: 20 }}
              width={700}
              height={300}
              series={[
                { data: uData, area: true, color: "green", label: t("Closed") },
                { data: pData, color: "darkred", label: t("Created") },
              ]}
              sx={{
                direction: "ltr",
                "--ChartsLegend-itemMarkSize": "25px",
                "--ChartsLegend-rootOffsetY": "-2px",
                "--ChartsLegend-rootSpacing": "-30%",
              }}
              xAxis={[{ scaleType: "point", data: xLabels }]}
            />
          )
        )}
      </GridDX>
      <GridDX item xs={12} lg={4} flexDirection={"column"}>
        <Typography sx={{ mt: 2 }}>{t("More Stats")}</Typography>
        <List>
          <Divider />
          {loadingStatistics ? (
            <div style={{ minHeight: "200px", position: "relative" }}>
              <Loading
                styles={{
                  height: "100%",
                  width: "100%",
                  left: 0,
                  position: "absolute",
                }}
              />
            </div>
          ) : (
            stats?.map((text: any, index: number) =>
              text.name === "Divider" ? (
                <Divider key={index} />
              ) : (
                <ListItem
                  key={index}
                  disablePadding
                  divider
                  sx={{
                    paddingLeft: "4px",
                    ":hover": {
                      paddingLeft: "2px",
                      borderLeft: "2px solid darkCyan",
                      backgroundColor: "rgba(0,139,139,.05)",
                    },
                  }}
                >
                  <ListItemButton
                    disableGutters
                    sx={{
                      cursor: "default",
                      ":hover": {
                        paddingLeft: "2px",
                        backgroundColor: "rgba(0,0,0,0)",
                      },
                    }}
                  >
                    <BoxDX display={"flex"} paddingX={1}>
                      <ListItemIcon>{text.icon}</ListItemIcon>

                      <ListItemText
                        primary={text.name}
                        sx={{ marginLeft: "-3px" }}
                        disableTypography
                      />
                    </BoxDX>
                  </ListItemButton>
                  <BoxDX
                    sx={{
                      backgroundColor: "#bba20b",
                      width: 50,
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      color="white"
                      fontSize={14}
                      sx={{ textAlign: "center" }}
                    >
                      {text.Badge}
                    </Typography>
                  </BoxDX>
                </ListItem>
              )
            )
          )}
        </List>
      </GridDX>
    </GridDX>
  );
};

export default Statistics;
